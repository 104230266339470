import React from "react";
import { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home  from './pages/homepage'
import Login from './pages/login'
import EditProfile from './pages/editProfile'
import Upload from './pages/upload'
import Article from './pages/article'
import Register from './pages/register'
import SignUp from './pages/signup'

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import Profile from "./pages/profile";
import { GoogleAuthProvider } from "firebase/auth";
import Jobs from "./pages/jobs";
import ArticleListing from "./pages/articleListing";

const firebaseConfig = {
  apiKey: "AIzaSyCAOFh3Z_kpVKRwh_Nr-LqrFHPkr6bk24I",
  authDomain: "kaamka-dotcom.firebaseapp.com",
  projectId: "kaamka-dotcom",
  storageBucket: "kaamka-dotcom.appspot.com",
  messagingSenderId: "181711745091",
  appId: "1:181711745091:web:d7ea3788819e4eb30724f6",
  measurementId: "G-QM7326P50P"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();  
const realtimeDB = getDatabase()
export {db,auth, realtimeDB}



const App: React.FC = () => {


  useEffect(() => {

  }, []);


// Export firestore database
// It will be imported into your react app whenever it is needed



  return (
    <div>

      <div >
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/articles/:type" element={<ArticleListing />} />

        </Routes>
      </div>
    </div>
  );
};

export default App;

