import React, { useState, useEffect } from "react";
import { Button, TextField, Autocomplete, Chip } from "@mui/material";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import lofi8 from "../assets/lofi8.png"
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../App";


function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

interface skills {
  id: number;
  name: string;
}


const userList: skills[] = [
  { id: 1, name: "Guitar" },
  { id: 2, name: "Drums" },
  { id: 3, name: "Bass" },
  { id: 4, name: "Keyboard" },
  { id: 5, name: "Production" },
];


const EditProfile: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [user,set_user] = useState<any>(null)
  const [bio,set_bio] = useState<any>(null)
  const [skills,set_skills] = useState<any>(null)
  const [location,set_location] = useState<any>(null)
  const [value, setValue] = React.useState<any>([userList[0].name]);

  const [profession,set_profession] = useState<any>(null)

  const navigate = useNavigate();


  useEffect(() => {

    //fetchPost();

    let user = localStorage.getItem('user');
    let profession = localStorage.getItem('profession');

    if(user != null){
      console.log('udss',JSON.parse(user).username);

      set_user(JSON.parse(user));
      set_profession(profession)
    }
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    // getUser()

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[]);

  function getUser(){

    let user = localStorage.getItem("user")

    if(user !== undefined && user !== null){
      set_user(user)
    }
    else{
      set_user("")
    }
  }


  async function saveProfile(){

    console.log('saveProfile', user, bio, skills, location, profession)

    const userRef = doc(db, "users",user.userId);

      await updateDoc(userRef, {
        skills:skills,
        location:location,
        bio:bio
      });

  }




if(user!= null){
 if(window.innerWidth > 650){
  return (


    <div style={{margin:0,  backgroundImage:"url("+lofi8+")",backgroundSize:"cover",  flexDirection:'column', backgroundColor:'#35063e', zIndex:99999, minHeight:'100vh', backgroundRepeat:'repeat', paddingBottom:100, display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:200, paddingRight:200,paddingTop:40}} >

        <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>

        {/* <p style={{marginTop:-40, marginBottom:40, fontStyle:'italic', color:'white'}}>Aap kya karte hain ?</p> */}

        <div style={{display:'flex',flexDirection:'row', height:240, width:'60%', justifyContent:'center',borderRadius:50, alignItems:'center'}}>

            <div style={{flex:1,borderBottomLeftRadius:10,borderTopLeftRadius:10, backgroundColor:"#280137", justifyContent:'center',alignItems:'center', display:'flex', height:'100%', margin:0}}>

            {user != null?
              // <img src={user.photoURL?user.photoURL:null} style={{height:90,width:90}}/>
              <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
              <p style={{fontSize:28,backgroundColor:"black",color:'white',padding:50,borderRadius:40}}>{(user.username).substring(0, 1)}</p>
              <p style={{fontSize:12,color:'white'}}>Change Picture</p>
              </div>
              :
              null}
              

            </div>

            <div style={{flex:1,borderBottomRightRadius:10,borderTopRightRadius:10, flexDirection:'column', backgroundColor:"#280137", height:'100%', margin:0,justifyContent:'center',alignItems:'center', display:'flex',}}>

                <h3 style={{color:'white'}}>{user.username}</h3>

                <h2 style={{textAlign:'center',color:'white'}}>{profession}</h2>

                {user.profile === undefined
                ?
                <Button variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Edit Profile</Button>
                :
                <p>Profile</p>
                }

            </div>

        </div>

        <div style={{display:'flex',backgroundColor:"#280137", flexDirection:'column', marginTop:40, padding:50, width:'60%', paddingBottom:20, justifyContent:'center',borderRadius:10, alignItems:'center'}}>


                <p style={{color:'white'}}>About Me</p>

                <div style={{margin:5}}>
                <TextField placeholder="A little about you." onChange={(event)=>set_bio(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />

                </div>

                <p style={{color:'white'}}>Skills </p>


                <div style={{margin:5}}>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      multiple
                      id="tags-filled"
                      options={userList.map((option) => option.name)}
                      freeSolo
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Skills"
                          style={{backgroundColor:"white", width:400}}
                          placeholder="Search"
                        />
                      )}
                    />
                </div>


                <p style={{color:'white'}}>Location </p>

                <div style={{margin:5}}>
                <TextField placeholder="Your location." onChange={(event)=>set_location(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />

                </div>
                
                <Button onClick={()=>saveProfile()} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', marginTop:20, height:40, width:'80%', borderRadius:20, fontSize:12}}>Save</Button>

        </div>

        <div>
    
    </div>
    </div>

    

);


 }
 else{

  return (



    <div style={{margin:0,backgroundImage:"url("+lofi8+")",backgroundSize:"cover",backgroundPosition:'center',maxWidth:'100%', width:'100%',  flexDirection:'column', backgroundColor:'white', zIndex:99999, height:'100vh', display:'flex', justifyContent:'flex-start', alignItems:'center', paddingLeft:2, paddingRight:2,paddingTop:40}} >

        <img src={logo} style={{height:40 ,borderRadius:20, margin:20, marginBottom:50}}/>

        <div style={{display:'flex',flexDirection:'row', height:240, width:'60%', justifyContent:'center',borderRadius:50, alignItems:'center'}}>

            <div style={{flex:1,borderBottomLeftRadius:10,borderTopLeftRadius:10, backgroundColor:"#280137", justifyContent:'center',alignItems:'center', display:'flex', height:'100%', margin:0}}>

            {user != null?
              // <img src={user.photoURL?user.photoURL:null} style={{height:90,width:90}}/>
              <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
              <p style={{fontSize:28,backgroundColor:"black",color:'white',padding:50,borderRadius:40}}>{(user.username).substring(0, 1)}</p>
              <p style={{fontSize:12,color:'white'}}>Change Picture</p>
              </div>
              :
              null}
              

            </div>

            <div style={{flex:1,borderBottomRightRadius:10,borderTopRightRadius:10, flexDirection:'column', backgroundColor:"#280137", height:'100%', margin:0,justifyContent:'center',alignItems:'center', display:'flex',}}>

                <h3 style={{color:'white'}}>{user.username}</h3>

                <h2 style={{textAlign:'center',color:'white'}}>{profession}</h2>

                {user.profile === undefined
                ?
                <Button variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Edit Profile</Button>
                :
                <p>Profile</p>
                }

            </div>

        </div>

        <div style={{display:'flex',backgroundColor:"#280137", flexDirection:'column', marginTop:40, padding:50, width:'60%', paddingBottom:20, justifyContent:'center',borderRadius:10, alignItems:'center'}}>


                <p style={{color:'white'}}>About Me</p>

                <div style={{margin:5}}>
                <TextField placeholder="A little about you." onChange={(event)=>set_bio(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />

                </div>

                <p style={{color:'white'}}>Skills </p>


                <div style={{margin:5}}>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      multiple
                      id="tags-filled"
                      options={userList.map((option) => option.name)}
                      freeSolo
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Skills"
                          style={{backgroundColor:"white", width:400}}
                          placeholder="Search"
                        />
                      )}
                    />
                </div>


                <p style={{color:'white'}}>Location </p>

                <div style={{margin:5}}>
                <TextField placeholder="Your location." onChange={(event)=>set_location(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />

                </div>
                
                <Button onClick={()=>saveProfile()} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', marginTop:20, height:40, width:'80%', borderRadius:20, fontSize:12}}>Save</Button>

      </div>
    </div>


);
 }
}
else{
  return null;
}

  
};

export default EditProfile;
